import {
  Box,Button,Heading,Stack,Text,Modal,ModalOverlay,ModalContent,ModalBody,
  useToast,useColorModeValue,Grid,useDisclosure,
  Collapse,FormControl,FormLabel,Input,FormHelperText,Textarea,Select,
  BreadcrumbItem,Breadcrumb,BreadcrumbLink,Tooltip,InputGroup,InputLeftElement,InputRightElement,ButtonGroup,
  Center,HStack
} from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'
import React,{useEffect,useState} from 'react'
import { FiChevronRight,FiMinus, FiPlus } from 'react-icons/fi'
import { GrLocation } from 'react-icons/gr'
import {SingleDatepicker} from 'chakra-dayzed-datepicker'
import  { Gallery } from '../productGallary/Gallery';
import { PriceTag } from './PriceTag'
import { useDispatch,useSelector } from 'react-redux';
import {addToCart,addToDummyCart} from '../../redux/slices/cartSlice';
import Login from '../Login';
import Logout from '../Logout';
import { useNavigate } from 'react-router-dom';
import { AttributePicker } from './AttributePicker'
import { useForm } from "react-hook-form";
import http from "../../http-common";
import { format } from 'date-fns';
import axios from 'axios'
import { Share } from './Share'
import { FaFilePdf  } from 'react-icons/fa';
import { Helmet } from "react-helmet";
import DisplayHTML from '../../Basic/DisplayHTML'
export const AppProduct = ({product_id,proddetails,images,attr,attrmaster}) => {
  const { register, handleSubmit,getValues,setValue,watch  } = useForm();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [price,setSalesPrice] = React.useState();
  const [timer, setTimer] = useState(null);

  const dispatch = useDispatch();
  const companysetting  = useSelector((state) => state.company);
  const timeslot_arr  = useSelector((state) => state.timeslot);
  const cartlabel  = useSelector((state) => state.cart.label);

  const cart_pincode = useSelector((state) => state.cart.pincodes);
  const cart_delstatus = useSelector((state) => state.cart.del_status);
  const cart = useSelector((state) => state.cart.cart);

  const id = product_id;
  const title = proddetails.name;
  //const price = proddetails.price1_min;
  const image = proddetails.photo_path;
  const description = proddetails.description;
  const currentstock = proddetails.current_stock;

  let pincode_msg = "";
  let btn_disable_pp = false;
  /*if(localStorage.getItem("phptoken") == "1" && proddetails.transaction_type == 22){
    pincode_msg = cart_delstatus > 0 ? 'Yes, we offer "delivery" services at this location.' : "oops! We're sorry, but we don't serve at this location!"
    btn_disable_pp = cart_delstatus > 0 ? false : true;
  }*/
  let navigate = useNavigate();
  const [show, setShow] = React.useState(false);
  const [pincodestatus, setPincodeStatus] = React.useState(cart_delstatus);
  const [pincodestatmsg, setPincodeStatusMsg] = React.useState(pincode_msg);
  const [pincodeval, setPincodeVal] = React.useState();
  const [btndisabled, setButtonDisabled] = React.useState(btn_disable_pp);
  const [weight, setWeight] = React.useState("");
  const [date, setDate] = useState(new Date());
  const [selTimeslot,setSelTimeslot] = useState("");
  const [messageoncake,setMessageOnCake] = useState("");
  const [buttonname,setButtonName] = useState("");
  const [quantity,setQuantity] = useState(1);
  const [qtydisbaled,setQtyDisabled] = useState(true);
  const [datafile, setFile] = useState({});
  const enable_product_code = companysetting.length > 0 && companysetting[0].DATA.enable_product_code != null ? companysetting[0].DATA.enable_product_code : "";
  const base_url = useSelector(state => state.baseurl.baseURL);             //window.location.origin; // "https://gadgets.ondemandcrm.co";   //"https://fashion.ondemandcrm.co"; //window.location.origin;//"https://ecommerce.ondemandcrm.co";
  const base_host_name = useSelector(state => state.baseurl.basehostname);  // window.location.hostname; //"bharatkrishna.ondemandcrm.co"; //"gadgets.ondemandcrm.co"; 
  const handleToggle = () => setShow(!show);
  const toast = useToast();
  const addcartstatus = "";
  console.log(proddetails, "proddetails..")

  useEffect(()=>{
    setSalesPrice(proddetails.price1_min);
    setWeight(proddetails.weight_atr);
  },[proddetails.price1_min]);

  useEffect(()=>{
    if(proddetails.transaction_type == 22){
      let pincode_msg = "";
      let btn_disable_pp = false;
      if(localStorage.getItem("phptoken") == "1"){
        pincode_msg = cart_delstatus > 0 ? 'Yes, we offer "delivery" services at this location.' : "oops! We're sorry, but we don't serve at this location!"
        btn_disable_pp = cart_delstatus > 0 ? false : true;
      }
      setPincodeVal(cart_pincode);
      setPincodeStatus(cart_delstatus);
      setPincodeStatusMsg(pincode_msg);
      //setButtonDisabled(btn_disable_pp);
    }
    
  },[cart_pincode]);
  
  const onSubmit = async (data) =>{
    var newlengthid = cart.length + 1;
    if((base_host_name == "vanillathebakery.ondemandcrm.co" || base_host_name == "cakes.ondemandcrm.co")){
      if((base_host_name == "vanillathebakery.ondemandcrm.co" || base_host_name == "cakes.ondemandcrm.co") && proddetails.category_name == "Photo Cakes" && datafile.name == undefined){
        toast({
          title: 'Upload Personalized Image',
          description: "Personalized image is required.",
          position: 'top-left',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return false;
      }
      if((base_host_name == "vanillathebakery.ondemandcrm.co" || base_host_name == "cakes.ondemandcrm.co") && selTimeslot == ""){
        toast({
          title: 'Please select time slot',
          description: "Time Slot is required.",
          position: 'top-left',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return false;
      }
      const val_attr = data.Attr;
      //console.log("Attribute Label");console.log(val_attr);
      const newArray =  val_attr && Object.keys(val_attr).length > 0 && Object.entries(val_attr).map(([key, value]) => ({
        id: key,
        value
      })).filter(obj => obj.value !== '');;
      
      const matchedValues = newArray && newArray.length > 0 && newArray.map(item => {
        if(item.value != ""){
          const match = attrmaster.find(obj => obj.ID == item.id);
          return {
            id: item.id,
            Name:match ? match.Name : null,
            value: match ? match.Masters[item.value].N : null
          };
        }
      });
      const deldate = format(date, 'yyyy/MM/dd');
      let urls = base_url + '/ecommerce_api/savetempimage/';
      const headers = {
          'content-type': 'multipart/form-data'
      };
      const fd = new FormData();
      if (datafile.name) {
          fd.append('photo', datafile,datafile.name);
      }
      fd.append('contact_id', localStorage.getItem("contact_id"));
      fd.append('product_id', id);
      const ress = await axios.post(urls, fd, headers);
      if (ress.data[0].STATUS === "SUCCESS") {
        if(ress.data[0].DATA > 0){
          const temp_image_id = ress.data[0].DATA; 
          if(localStorage.getItem("phptoken") == 1){
            dispatch(addToCart({id, title, image,price,description,val_attr,deldate,selTimeslot,messageoncake,temp_image_id,quantity,newlengthid,matchedValues})); 
            toast({
              title: 'Product added to the cart',
              description: "It has been added to the cart successfully.",
              position: 'top-left',
              status: 'success',
              duration: 5000,
              isClosable: true,
            });
            if(buttonname == "BuyNow"){
              {/* /ecommerce Remove  */}
              //console.log("POST TO CART");
              navigate("/cart");
            }
          }else{
            //console.log("LOG1");
            dispatch(addToDummyCart({id, title, image,price,description,val_attr,deldate,selTimeslot,messageoncake,temp_image_id,quantity,newlengthid,matchedValues}));    
            onOpen();
          }
          
        }
      }else{
        //console.log("WEB RECORDS 1");
        if(localStorage.getItem("phptoken") == 1){
          dispatch(addToCart({id, title, image,price,description,val_attr,deldate,selTimeslot,messageoncake,quantity,newlengthid,matchedValues}));
          toast({
            title: 'Product added to the cart',
            description: "It has been added to the cart successfully.",
            position: 'top-left',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          if(buttonname == "BuyNow"){
            // remove ecommerce
            navigate("/cart");
          }
        }else{
          //console.log("Summycart");
          //console.log("LOG2");
          dispatch(addToDummyCart({id, title, image,price,description,val_attr,deldate,selTimeslot,messageoncake,quantity,newlengthid,matchedValues}));
          onOpen();
        }
      }
    }else{

     // console.log("Check Attribute");
      //console.log(id);
      const val_attr = data.Attr;
      //const dataArray = Object.entries(val_attr);
      const newArray =  val_attr && Object.keys(val_attr).length > 0 && Object.entries(val_attr).map(([key, value]) => ({
        id: key,
        value
      })).filter(obj => obj.value !== '');
      
      const resultString = newArray && newArray.length > 0 && newArray.map(obj => obj.id + "_" + obj.value).join("_");
      const master_key = resultString !== undefined && resultString !== null ? id + "_" + resultString : id;
      
      const matchedValues = newArray && newArray.length > 0 && newArray.map(item => {
        const match = attrmaster.find(obj => obj.ID == item.id);
        return {
          id: item.id,
          Name:match ? match.Name : null,
          value: match ? match.Masters[item.value].N : null
        };
      });
      //console.log(matchedValues);
      
      const deldate = format(date, 'yyyy/MM/dd');
      if(localStorage.getItem("phptoken") == 1){
        dispatch(addToCart({id, title, image,price,description,val_attr,deldate,selTimeslot,messageoncake,quantity,newlengthid,matchedValues,master_key}));
        toast({
          title: 'Product added to the cart',
          description: "It has been added to the cart successfully.",
          position: 'top-left',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        if(buttonname == "BuyNow"){
          {/* /ecommerce Remove  */}
          navigate("/cart");
        }
      }else{
        //console.log("Buy Now");
        console.log("LOG3222");
        dispatch(addToDummyCart({id, title, image,price,description,val_attr,deldate,selTimeslot,messageoncake,quantity,newlengthid,matchedValues,master_key}));
        onOpen();
      }
    }
  } 
  function handleChangeProductFile(e) {
      const photofile = e.target.files[0];
      setFile(photofile);
      //setFile(URL.createObjectURL(e.target.files[0]));
    }
  const handleChange = async (e) => {
    setButtonDisabled(true);
    setValue(e.target.name, e.target.value);
    const values = getValues();
    var index = e.nativeEvent.target.selectedIndex;
    setWeight(e.nativeEvent.target[index].text != "Select option" ? e.nativeEvent.target[index].text : "");
    let url = '/ecommerce_api/getProductAttributePriceList/';
    const res = await http.post(url, {
        AUTHORIZEKEY: "",
        attributes: values,
        product_id:id
    }).then((res) => {
        if (res.data[0].STATUS == "SUCCESS") {
            setSalesPrice(res.data[0].DATA.price);
            setButtonDisabled(false);
        }else{
          setButtonDisabled(false);
        }
    });
    // Do something with values, in my case I have used the 'setActiveFilter' function above.
  };
  const handleCheckPincode = async (e) =>{
    e.preventDefault();
    setPincodeVal(e.target.value);
    setButtonDisabled(true);
    setPincodeStatusMsg("");
    
    const pincode = e.target.value;
    if (timer) {
        clearTimeout(timer);
        setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        let url = '/ecommerce_api/checkShippingPincode/';
        const res = http.post(url, {
            AUTHORIZEKEY: "",
            pincode:pincode
        }).then((res) => {
            if (res.data[0].STATUS == "SUCCESS") {
              setPincodeStatus(res.data[0].DATA);
              setPincodeStatusMsg(res.data[0].MSG);
              setButtonDisabled(false);
            }else{
              setPincodeStatus(0);
              setPincodeStatusMsg(res.data[0].MSG);
            }
        });
      }, 1000)
    );
  }

  const decreaseQuantity = () =>{
    if(quantity == 1){
      setQtyDisabled(true);
      return false;
    }
    setQuantity(quantity - 1);
  }

  const inceaseQuantity = () =>{
    /*const fn_qty = quantity + 1;
    if(fn_qty > currentstock){
      alert("
      
      ");
      return false;
    }*/
    setQuantity(quantity + 1);
    setQtyDisabled(false);
  }
  
  return (
    <Box
      maxW="7xl"
      mx="auto"
      className="Ecomm-Group"
      px={{
        base: '4',
        md: '8',
        lg: '12',
      }}
      py={{
        base: '6',
        md: '8',
        lg: '12',
      }}
    >
      {proddetails.meta_title != "" &&
      <Helmet>
        <title>{proddetails.meta_title}</title>
        <meta name="description" content={proddetails.meta_description}  />
      </Helmet>
      }
      <Breadcrumb mb={5} spacing='8px' separator={<FiChevronRight color='gray.500' style={{marginTop:"4px"}} />}>
        <BreadcrumbItem>
          <BreadcrumbLink onClick={()=>navigate('/')} >Home</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink onClick={() => { navigate(`/filter/${proddetails.category_name.replace(/\s+/g, '-')}`); }}>{proddetails.category_name}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink cursor="default">{proddetails.name}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb> 
      <Stack
        direction={{
          base: 'column-reverse',
          lg: 'row',
        }}
        spacing={{
          base: '6',
          lg: '12',
          xl: '16',
        }}
        
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            spacing={{
              base: '6',
              lg: '8',
            }}
            maxW={{
              lg: 'sm',
            }}
            minW={{
              lg: 'sm',
            }}
            justify="center"
          >
            <Stack
              spacing={{
                base: '3',
                md: '4',
              }}
            >
              <Stack spacing="3">
                  <Heading as='h1' size="md" fontWeight="medium">
                    {proddetails.name}
                    {/* {weight && <span> - {weight}</span>}  */}
                  </Heading>
                  {enable_product_code == "Y" &&
                  <HStack>
                    <Text fontWeight="medium" fontSize="sm" 
                    //color={useColorModeValue('gray.800', 'gray.200')}
                    >
                      <b>Code : {proddetails.code}</b>
                      </Text>
                  </HStack> 
                  }
                  {proddetails.brand_name &&
                    <HStack>
                    <Text fontWeight="medium" fontSize="sm" 
                    //color={useColorModeValue('gray.800', 'gray.200')}
                    >
                      <b>Brand Name : {proddetails.brand_name}</b>
                      </Text>
                    </HStack> 
                  }
                  {proddetails.transaction_type == 22 &&
                    <>
                      {proddetails.current_stock > 0 ? <Text color="green.800"><b>In stock</b></Text> : <Text color="red.800"><b>Out of stock</b></Text>} 
                    </>
                  }
              </Stack>
              {parseFloat(price) > 0 &&
                <PriceTag
                  price={price > 0 ? price : 0}
                  currency="INR"
                  rootProps={{
                    fontSize: 'xl',
                  }}
                />
              }
                <Text fontSize='md'><b>Quantity</b></Text>
              <ButtonGroup  size='md' colorScheme='gray'  mt={1} isAttached variant='solid'>
                <Button
                  disabled={qtydisbaled}
                  onClick={() => decreaseQuantity()}
                >
                  <FiMinus />
                </Button>
                <Center minW="2">
                  <Text as="span" mb={1} fontWeight="semibold" userSelect="none" ml={3} mr={3}>
                    {quantity}
                  </Text>
                </Center>
                <Button
                  onClick={() => inceaseQuantity()}
                >
                <FiPlus />
                </Button>
                </ButtonGroup>
                {attrmaster &&
                  <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                    {attrmaster.map((master) =>(
                      <Stack flex="1">
                        <Text as='b' fontSize='sm'>{master.Name}</Text>
                        <AttributePicker  options={master} register={register} handleChange={handleChange}  />
                      </Stack>
                    ))}
                  </Grid>
                }
              
                {(base_host_name == "vanillathebakery.ondemandcrm.co" || base_host_name == "cakes.ondemandcrm.co") &&
                <>
                  <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                    <FormControl>
                      <FormLabel>Delivery Date</FormLabel>
                      <SingleDatepicker
                        name="date-input"
                        date={date}
                        onDateChange={setDate}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Time Slot</FormLabel>
                      <Select placeholder='Select option' onChange={(e)=>{setSelTimeslot(e.target.value)}}>
                      {(timeslot_arr.timeslot.length > 0) && timeslot_arr.timeslot.map((tslot) =>
                        <option value={tslot.value}>{tslot.label}</option>
                      )}
                    </Select>
                    </FormControl>
                  </Grid>
                
                  <FormControl>
                    <Textarea placeholder='Message on cake' onChange={(e)=>{setMessageOnCake(e.target.value)}} />
                  </FormControl>
                  {proddetails.category_name == "Photo Cakes" &&
                    <FormControl>
                      <FormLabel>Upload Personalized Image</FormLabel>
                      <Input  type="file" onChange={handleChangeProductFile}/>
                      <FormHelperText>File size between 100 KB - 10 MB. Only JPG, JPEG, PNG.</FormHelperText>
                    </FormControl>
                  }
                </>
                }
                {/* {proddetails.transaction_type == 22 &&
                  <>
                    <FormLabel>Please enter the "pincode" number of the location where you require "delivery".</FormLabel>
                    <InputGroup>
                        <InputLeftElement
                          pointerEvents='none'
                          color='gray.300'
                          fontSize='1.2em'
                          children={<GrLocation color='gray.400'/>}
                        />
                        <Input placeholder='Enter Pincode' value={pincodeval} minLength={6} maxLength={6} onChange={handleCheckPincode}/>
                        <InputRightElement children={<CheckIcon color={pincodestatus > 0 ? 'green.600' : 'gray.400'} />} />
                    </InputGroup>
                    <Text color={pincodestatus > 0 ? `green.600` : `red.400`}>{pincodestatmsg}</Text>
                  </>
                } */}
            </Stack>
              
              <Text color={useColorModeValue('gray.600', 'gray.400')}>
              {proddetails.product_id > 0 && proddetails.description != "" &&
                  <>
                  <Collapse startingHeight={70} in={show}>
                    <DisplayHTML  htmlContent={proddetails.description} />
                    {/* <div dangerouslySetInnerHTML={{ __html: proddetails.description }}></div> */}
                  </Collapse>
                  <Button size='sm' onClick={handleToggle} mt='1rem'>
                    Show {show ? 'Less' : 'More'}
                  </Button>
                </>
              }
              </Text>
              {(companysetting.length > 0 && companysetting[0].DATA.theme_apply_link != null) ?  
              <Text>
                <Button size="sm" onClick={()=>window.open(companysetting[0].DATA.theme_apply_link, "_blank", "noreferrer")} colorScheme='teal' >
                  Enquire Now
                </Button>
              </Text>
              : ""}
              {/* {proddetails.document_path !== null && proddetails.document_path.trim() !== '' ? 
              <Text><Button onClick={()=>window.open(`${proddetails.document_path}`)} leftIcon={<FaFilePdf />} size="sm" colorScheme='red' variant='solid'>
                View Brocedure
              </Button></Text> : "" } */}
              {proddetails.transaction_type == 22 && proddetails.current_stock < 1 ? "" :
              <>
              <Stack direction='row' spacing={4} align='center'>
                {localStorage.getItem("phptoken") == "1" ? 
                  <Button onClick={()=>setButtonName("AddCart")}  borderRadius={5} colorScheme={'blue'} size="lg" width='100%' type='submit' disabled={btndisabled}>
                    {(companysetting.length > 0 && companysetting[0].DATA.label_cart != null) ? companysetting[0].DATA.label_cart : "Add to Cart"}
                  </Button> : <Button borderRadius={5} colorScheme={'blue'} size="lg" width='100%' type='submit' disabled={btndisabled}>
                    {(companysetting.length > 0 && companysetting[0].DATA.label_cart != null) ? companysetting[0].DATA.label_cart : "Add to Cart"}
                  </Button>
                }
              </Stack>
              <Stack direction='row' spacing={2} align='center'>
                {localStorage.getItem("phptoken") == "1" ? 
                  <Button onClick={()=>setButtonName("BuyNow")} borderRadius={5} colorScheme={'green'} size="lg" width='100%' type='submit' disabled={btndisabled}> 
                    {(companysetting.length > 0 && companysetting[0].DATA.label_buynow != null) ? companysetting[0].DATA.label_buynow : "Buy Now"}
                  </Button> : 
                  <Button 
                    borderRadius={5} 
                    colorScheme={'green'} 
                    size="lg" 
                    width='100%' 
                    //type='submit'
                    onClick={onOpen} 
                    disabled={btndisabled}>
                    {(companysetting.length > 0 && companysetting[0].DATA.label_buynow != null) ? companysetting[0].DATA.label_buynow : "Buy Now"}
                  </Button>
                }
              </Stack>
              </>
              }
            <Share url_photo_prod={proddetails.original_photo_path} product_id={proddetails.product_id} category_name={proddetails.category_name} product_name={proddetails.name} />
          </Stack>
        </form>
        <Box flex="1">
          <Gallery images={images.slice(0, 5)} />
        </Box>
      </Stack>
      <Modal
          isOpen={isOpen}
          closeOnOverlayClick={false}
          size="2xl"
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent borderRadius="2xl" mx="4">
            {/* <ModalCloseButton /> */}
            <ModalBody>
              {localStorage.getItem("phptoken") == "1" ? <Logout onClose={onClose}/> : <Login onClose={onClose} /> }
            </ModalBody>
          </ModalContent>
        </Modal>
    </Box>
    
  )
}
