import {
  chakra,
  HStack,
  Icon,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';
import { FaEnvelope, FaFacebook, FaPinterest, FaTwitter } from 'react-icons/fa';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  EmailShareButton,
  EmailIcon,
  XIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share';
import { useLocation } from 'react-router-dom';
import HelmetMetaData from './HelmetMetaData';
import Helmet from 'react-helmet';
import { FacebookButton, FacebookCount } from 'react-social';
import { useSelector } from 'react-redux';

export const Share = props => {
  const {
    label = 'Share',
    url_photo_prod,
    product_id,
    category_name,
    product_name,
    rootProps,
  } = props;
  let location = useLocation();
  let title = 'Product';
  const LINK_TYPE = process.env.REACT_APP_LINK_TYPE;
  const domain = window.location.hostname; //"fashion.ondemandcrm.co";
  const baseurl = useSelector(state => state.baseurl.baseURL);
  let currentUrl =
    LINK_TYPE == 1
      ? `${baseurl}/ecommerce${location.pathname}`
      : `https://www.murdart.in${location.pathname}`;
  //"https://ecommerce.ondemandcrm.co/ecommerce/product/Mzk=";//window.location.origin + location.pathname;

  //document.getElementById("meta_image_url").content = url_photo_prod;
  return (
    <Stack {...rootProps}>
      <Text
        fontSize="sm"
        fontWeight="medium"
        color={useColorModeValue('gray.600', 'gray.400')}
      >
        {label}
      </Text>
      <HStack spacing="5">
        <Helmet>
          <meta property="og:title" content={`${product_name}`} />
          <meta
            property="og:description"
            content={'Product Description By Gadgets'}
          />
          <meta property="og:url" content={currentUrl} />
          <meta property="og:image" content={url_photo_prod} />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Gadgets" />
          <meta property="og:image:width" content="1920" />
          <meta property="og:image:height" content="1280" />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:type" content="image/gif" />
        </Helmet>
        <HelmetMetaData
          title={title}
          description={category_name + product_name}
          image={url_photo_prod}
          currentUrl={currentUrl}
        ></HelmetMetaData>

        <WhatsappShareButton
          url={currentUrl}
          title={`${category_name} - ${product_name}`}
          separator=":: "
        >
          <WhatsappIcon size={36} />
        </WhatsappShareButton>
        <FacebookShareButton
          url={currentUrl}
          title={`${category_name} - ${product_name}`}
          separator=":: "
        >
          <FacebookIcon size={36} />
        </FacebookShareButton>
        <TwitterShareButton
          url={currentUrl}
          title={`${category_name} - ${product_name}`}
          hashtag="#onlineorders"
        >
          <XIcon size={36} />
        </TwitterShareButton>
        <TelegramShareButton
          url={currentUrl}
          title={`${category_name} - ${product_name}`}
          separator=":: "
        >
          <TelegramIcon size={36} />
        </TelegramShareButton>
        <EmailShareButton
          url={currentUrl}
          title={`${category_name} - ${product_name}`}
          separator=":: "
        >
          <EmailIcon size={36} />
        </EmailShareButton>
        <LinkedinShareButton
          url={currentUrl}
          title={`${category_name} - ${product_name}`}
          separator=":: "
        >
          <LinkedinIcon size={36} />
        </LinkedinShareButton>
      </HStack>
    </Stack>
  );
};
