import {
  Box,
  HStack,
  Icon,
  Image,
  Link,
  Stack,
  Text,
  Collapse,
  Button,
  useColorModeValue as mode,
  OrderedList,
  ListItem,
  List,
  ListIcon,
} from '@chakra-ui/react';
import * as React from 'react';
import { FcServices } from 'react-icons/fc';
import { useSelector } from 'react-redux';

export const CartProductMeta = props => {
  const {
    isGiftWrapping = true,
    image,
    name,
    description,
    existingAttributeValue,
  } = props;
  const [show, setShow] = React.useState(false);
  const handleToggle = () => setShow(!show);
  const base_url = useSelector(state => state.baseurl.baseURL); //"https://ecommerce.ondemandcrm.co"; //window.location.origin;  //"https://gadgets.ondemandcrm.co"; //
  console.log(base_url, "base_url..from..CartProductMeta")
  const listItems =
    existingAttributeValue &&
    existingAttributeValue.map(item => (
      <ListItem>
        <ListIcon as={FcServices} color="green.500" />
        <b>{item.Name} </b>: {item.value}
      </ListItem>
    ));
  return (
    <Stack direction="row" spacing="5" width="full">
      <Image
        marginTop={4}
        rounded="lg"
        width="80px"
        height="80px"
        fit="cover"
        src={`${base_url}/viewimage/getproduct/${image}/normal`}
        alt={name}
        draggable="false"
        loading="lazy"
      />
      <Box pt="4">
        <Stack spacing="0.5">
          <Text fontWeight="medium">{name}</Text>
          {listItems && (
            <Text color={mode('gray.600', 'gray.400')} fontSize="sm">
              <List spacing={3}>{listItems}</List>
            </Text>
          )}
          {/* {description != "" &&
            <Text color={mode('gray.600', 'gray.400')} fontSize="sm">
              <Collapse startingHeight={20} in={show}>
                <div
                  dangerouslySetInnerHTML={{__html: description}}
                />
              </Collapse>
              <Button size='sm' onClick={handleToggle} mt='1rem'>
                Show {show ? 'Less' : 'More'}
              </Button>
            </Text>
          } */}
        </Stack>
      </Box>
    </Stack>
  );
};
